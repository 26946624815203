import { Col } from 'react-bootstrap';
import codeIcon from '../assets/img/code_icon.svg';
import linkIcon from '../assets/img/link_icon.svg';

export const ProjectCard = ({ title, description, imgUrl, url, urlCode }) => {
  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx">
        <img src={imgUrl} alt=" " />
        <div className="proj-txtx">
          <h4>{title}</h4>
          <span>{description}</span>
          <div className="social-icon">
            <a href={urlCode} target="_blank" rel="noreferrer">
              <img src={codeIcon} alt="Code" />
            </a>
            <a href={url} target="_blank" rel="noreferrer">
              <img src={linkIcon} alt="Link" />
            </a>
          </div>
        </div>
      </div>
    </Col>
  );
};
