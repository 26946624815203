import { useState, useEffect } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import logo from '../assets/img/LOGO_mini@0.1x.png';
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/nav-icon2.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';
import navIcon4 from '../assets/img/nav-icon4.svg';
import { BrowserRouter as Router } from 'react-router-dom';

export const NavBar = () => {
  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  };

  return (
    <Router>
      <Navbar expand="md" className={scrolled ? 'scrolled' : ''}>
        <Container>
          <Navbar.Brand href="/">
            <img src={logo} alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link
                href="#home"
                className={
                  activeLink === 'home' ? 'active navbar-link' : 'navbar-link'
                }
                onClick={() => onUpdateActiveLink('home')}
              >
                Home
              </Nav.Link>
              <Nav.Link
                href="#skills"
                className={
                  activeLink === 'skills' ? 'active navbar-link' : 'navbar-link'
                }
                onClick={() => onUpdateActiveLink('skills')}
              >
                Skills
              </Nav.Link>
              <Nav.Link
                href="#projects"
                className={
                  activeLink === 'projects'
                    ? 'active navbar-link'
                    : 'navbar-link'
                }
                onClick={() => onUpdateActiveLink('projects')}
              >
                Projects
              </Nav.Link>
            </Nav>
            <span className="navbar-text">
              <div className="social-icon">
                <a
                  href="https://www.linkedin.com/in/david-jovino-001a7964/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={navIcon1}
                    alt="https://cdn.jsdelivr.net/npm/simple-icons@v3/icons/linkedin.svg"
                  />
                </a>
                <a
                  href="https://github.com/DavidJovino"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={navIcon2}
                    alt="https://cdn.jsdelivr.net/npm/simple-icons@v3/icons/github.svg"
                  />
                </a>
                <a
                  href="https://www.instagram.com/jovino.david/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={navIcon3}
                    alt="https://cdn.jsdelivr.net/npm/simple-icons@v3/icons/instagram.svg"
                  />
                </a>
                <a
                  href="https://replit.com/@DavidJovino"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={navIcon4}
                    alt="https://cdn.jsdelivr.net/npm/simple-icons@3.13.0/icons/repl-dot-it.svg"
                  />
                </a>
              </div>
              <a
                href="https://wa.me/5519999114182?text=Hey!%20Let's%20connect%20and%20see%20how%20we%20can%20work%20together"
                target="_blank"
                rel="noreferrer"
              >
                <button className="vvd">
                  <span>Let’s Connect</span>
                </button>
              </a>
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Router>
  );
};
