import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import { ProjectCard } from './ProjectCard';
import projtravel from '../assets/img/Project_travel.jpg';
import projdashboard from '../assets/img/Project_dashboard.jpg';
import projImg1 from '../assets/img/project-img1.png';
import projImg2 from '../assets/img/project-img2.png';
import projImg3 from '../assets/img/project-img3.png';
import colorSharp2 from '../assets/img/color-sharp2.png';

import 'animate.css';
import TrackVisibility from 'react-on-screen';
import React from 'react';

export const Projects = () => {
  const projects = [
    {
      title: 'Travel API',
      description: 'A Travel Advisor using RapidAPI',
      imgUrl: projtravel,
      url: 'https://travelapi.davidjovino.com',
      urlCode: 'https://github.com/DavidJovino/RapidAPI_Travel_Advisor',
    },
    {
      title: 'Ecommerce Dashboard',
      description: 'Ecommerce Dashboard with Refine',
      imgUrl: projdashboard,
      url: 'https://ecommercedashboard.davidjovino.com/',
      urlCode: 'https://github.com/DavidJovino/Ecommerce_Dashboard',
    },
    {
      title: 'Business Startup',
      description: 'Design & Development',
      imgUrl: projImg3,
      url: '',
      urlCode: '',
    },
    {
      title: 'Business Startup',
      description: 'Design & Development',
      imgUrl: projImg1,
      url: '',
      urlCode: '',
    },
    {
      title: 'Business Startup',
      description: 'Design & Development',
      imgUrl: projImg2,
      url: '',
      urlCode: '',
    },
    {
      title: 'Business Startup',
      description: 'Design & Development',
      imgUrl: projImg3,
      url: '',
      urlCode: '',
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? 'animate__animated animate__fadeIn' : ''
                  }
                >
                  <h2>Projects</h2>
                  <p>
                    I am passionate about creating dynamic and user-friendly
                    websites, and I have a keen eye for detail and a commitment
                    to delivering high-quality work.
                  </p>
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Nav
                      variant="pills"
                      className="nav-pills mb-5 justify-content-center align-items-center"
                      id="pills-tab"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="first">Tab 1</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">Tab 2</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">Tab 3</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content
                      id="slideInUp"
                      className={
                        isVisible ? 'animate__animated animate__slideInUp' : ''
                      }
                    >
                      <Tab.Pane eventKey="first">
                        <Row>
                          {projects.map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="section">
                        <p></p>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <p></p>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt="#"></img>
    </section>
  );
};
